.Container {
  display: block;
  max-width: 80%;
  margin: 40px auto;
}
.Container p {
  font-size: 0.8em;
  font-weight: 300;
}
.SelectAll {
  display: flex;
  font-weight: 600;
  margin-bottom: 25px;
}
.SelectAll input {
  margin: 1px 8px 0 0;
}
.Grid {
  display: grid;
  line-height: 1.2em;
  grid-gap: 10px 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.Scope {
  display: flex;
  font-weight: 300;
  align-items: flex-start;
}
.Scope input {
  margin: 3px 8px 0 0;
}
.SubmitDisabled {
  background: var(--green-gray);
}