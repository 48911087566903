.ButtonTree > button {
	width: 100%;
	margin-top: 20px;
	padding: 20px 10px;
	background-color: #fff;
	color: #363636;
	border: none;
	background-color: #f8f8f8;
	position: relative;
	font-weight: 400;
	border-radius: 3px;
	text-align: left;
	padding-left: 60px;
	font-size: 1rem;
}

.Title {
	margin-bottom: 15px;
	font-weight: 500;
	font-size: 1.6rem;
}

.ButtonTree > button > div {
	position: absolute;
	left: 8px;
	width: 40px;
	height: 45px;
	padding: 5px;
	box-sizing: border-box;
	top: 8px;
	border-radius: 1000px;
	overflow: hidden;
}

.ButtonTree > button > div > svg {
	width: 100% !important;
	height: 100%;
}

.ButtonTree {
	margin-top: 30px;
}

.BtnSubInfo {
	font-weight: 300;
	font-size: 0.9rem;
	color: var(--grey);
	font-family: var(--muli);
}

.OtherSiteBtn {
	cursor: pointer;
	margin-top: 35px;
	font-size: 0.9rem;
	color: var(--dark-main-color);
}
.OtherSiteBtn:hover {
	color: var(--main-color);
}

