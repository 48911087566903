.ProfileContainer{
   margin-bottom: 25px;
}

.Profile {
	border-radius: 50%;
	width: 120px;
	height: 120px;
	max-height: 200px;
	display: block;
	object-fit: contain;
	object-position: initial;
	filter: none;
	margin: auto;
}
.Profile_square {
	border-radius: 50%;
	width: 150px;
	height: auto;
}