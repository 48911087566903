:root {
	--green: #295a4c;
	--yellow: #e8c547;
	--red: #f15555;
	--blue: #5cc0eb;
	--black: #0f0f0f;
	--white: #ffffff;
	--header-color: #1d1d1d;
	--paragraph-color: #282828;
	--green-gray: #8a9f99;
	--gray: #9da4a2;
	--icon-gray: #c1c6c5;
	--background-gray: #f5f6f8;
	--divider-gray: #ebeff2;
	--open-sans: 'Open Sans', sans-serif;
	--marker-font: 'Permanent Marker', cursive;
	--dm-sans: 'DM Sans', sans-serif;
	--light-drop-shadow: 0px 0px 18px rgba(54, 54, 54, 0.07);
	--drop-shadow: 0px 2px 15px -2px rgba(60, 60, 60, 0.25);
	--small-border-radius: 4px;
	--big-border-radius: 8px;
	--font-size: 16px;

	--column-gap: 32px;
	--main-font: var(--open-sans);

	--bottom-gap-mobile: 48px;

	/*font sizes*/
	--h1: 60px;
	--h2: 48px;
	--h3: 40px;
	--h4: 32px;
	--h5: 24px;
	--font-size-regular: 15px;
	--font-size-small: 13px;
	--font-size-large: 22px;
}

.App {
	font-family: var(--open-sans);
	color: var(--paragraph-color);
	box-sizing: border-box;
	font-size: var(--font-size);
	font-weight: 400;
	height: calc(var(--vh, 1vh) * 100);
   line-height: 28px;
}

button {
	font-family: var(--main-font);
	font-weight: 400;
   font-size: 15px;
}
